section.banners-127 {

    .banner-container {
        //.make-row();
        //.make-sm-column(10);
        //.make-sm-column-offset(1);

        div {
            a {
                &:extend(.banner all);

                h3 {
                    min-height: 33px;
                }

                span:after {
                    display: none !important;
                }
            }
        }


    }
    .col {
        .make-sm-column(6);
        .make-md-column(3);
        @media @small {
            margin-bottom: @grid-gutter-width;
        }
        @media @small {
            &:nth-child(2n+1){
                clear: both;
            }
        }
        @media @medium, @large {
            &:nth-child(4n+1){
                clear: both;
            }
        }
    }

}
