a.project-banner {
    display: block;
    position: relative;
    figure {
        height: 200px;
        background-size: cover;
        background-position: center;
        color: @white;
        text-decoration: none;
        overflow: hidden;

        @media @medium, @large {
            height: 200px;
        }

        @media @small {
            height: 180px;
        }

        @media @phone, @xsmall {
            height: 140px;
        }
    }
    div {
        position: absolute;
        bottom: 0;
        .lh-transition(background 0.2s ease);
        left: 0;
        right: 0;
        width: 100%;
        background-color: fade(@color1, 55%);
        padding: 10px 0;

        &:after {
            position: absolute;
            top: -14px;
            left: 50%;
            margin-left: -12px;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 12px 14px 12px;
            border-color: transparent transparent fade(@color1, 55%) transparent;

        }
    }
    h3 {
        .font-size(16) !important;
        text-align: center !important;
        color: @white !important;
        margin: 0 !important;
        padding: 5px 0 !important;
        border: none !important;
        font-family: @contentfont !important;
    }

    h5 {
        .font-size(14) !important;
        color: @white !important;
        text-align: center;
        line-height: 1.3;
        padding: 0;
        margin: 0 !important;
        overflow: hidden;
        border: none;
        max-height: 0px;
        font-family: @contentfont !important;
        .lh-transition(max-height 0.2s ease);
    }

    &:hover {
        div {

            background: fade(@color1, 55%);
            &:after {
                border-color: transparent transparent fade(@color1, 55%) transparent;
            }
        }
        h3, h5 {
            color: @white !important;
            text-decoration: none !important;
        }
        h5 {
            height: 100px;
            max-height: 100px;
        }
        text-decoration: none !important;
    }
}
