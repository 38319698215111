section.content-20 {

    .col1 {
        .make-sm-column(6);
		@media @phone, @xsmall {
			margin-bottom: 30px;
		}
    }

    .col2 {
        .make-sm-column(6);
    }
}
