section.content-22 {

    .col1 {
        .make-sm-column(4);
		@media @phone, @xsmall {
			margin-bottom: 30px;
		}
    }

    .col2 {
        .make-sm-column(8);
    }
}
