section.referenties-202 {
    .block {
        padding: 0 15px;

        .referentie {
            .make-xs-column(6);
            .make-sm-column(3);
            .make-md-column(2);
            text-align: center;
            margin-bottom: 25px;

            @media @phone, @xsmall {
                &:nth-child(2n+1) {
                    clear: both;
                }
            }
            @media @small {
                &:nth-child(4n+1) {
                    clear: both;
                }
            }
            @media @medium, @large {
                &:nth-child(6n+1) {
                    clear: both;
                }
            }


            span {
                display: block;
                color: @color1;
                padding: 3px 0;
                line-height: 1.3;
            }
            figure {
                img {
                    .lh-transition(transform 0.2s ease);
                }
            }
        }

        a.referentie {
            color: @color1;
            text-decoration: none;

            &:hover {
                color: @color2;
                span {
                    color: @color2;
                }
                figure {
                    img {
                        .lh-transform(scale(1.06));
                    }
                }
            }
        }
    }

}
