
.kop {
    font-family: @titlefont;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 1em;
}

.zerniq_wysiwyg {
    font-family: @contentfont;
    line-height: 1.8;
    color: @textcolor;

    h1, h2, h3, h4 {
        .kop;
    }
    h1 {
      color: @color1;
        .font-size(28);
        margin: 0 0 0.6em 0;
        @media @phone, @xsmall {
            .font-size(24);
        }
        & + h1,
        & + h2 {
            margin-top: -0.5em;
        }
    }

    h2 {
      color: @color2;
      .font-size(24);
        margin: 0 0 0.6em 0;
        @media @phone, @xsmall {
            .font-size(20);
        }
        & + h1,
        & + h2 {
            margin-top: -0.5em;
        }
    }

    h3, h4 {
      color: @color1;
        .font-size(24);
        margin: 0 0 1em 0;

        @media @phone, @xsmall {
            .font-size(22);
        }
    }
    h4 {
      color: @color2;
        .font-size(20);


    }

    h5, h6 {
        .kop;
        color: @textcolor !important;

        &.center-heading {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .block-title {
      .kop;
      .font-size(28);
      text-align: center;
      color: @color1;
      margin: 10px 0 20px 0;

      @media @phone, @xsmall {
        .font-size(24);
      }
    }

    a {
        color: @color1;

        &:hover {
            color: @color2;
        }
    }

    img {
        max-width: 100%;
        //height: auto !important;
    }

    p {
        margin: 0 0 1.3em 0;
    }

    p + ul,
    p + ol {
        margin-top: -1.5em;
    }

    time {
        display: block;
        margin-bottom: 10px;
        font-weight: 500;
        .font-size(15);
    }
    /*
    ol {
        margin: 0;
        padding: 0;
        list-style-type: none;
        counter-reset: item;

        li {
            position: relative;
            margin: 0;
            padding-left: 50px;
            color: @color1;
            min-height: 50px;
            counter-increment: item;
            .font-size(20);
            padding-top: 2px;

            &:before {
                position: absolute;
                left: 0;
                top: 0;
                content: counters(item,".") " ";
                width: 40px;
                height: 40px;
                .lh-border-radius(50%);
                background: @color1;
                color: @white;
                .font-size(18);
                font-weight: 400;
                text-align: center;
                padding-top: 3px;
            }

        }
    }
    */
    ol {
        margin: 0 0 1.3em 0;
    }
    ul {
        margin: 0 0 1.3em 0;
        @media @phone {
            padding-left: 15px;

            ul {
                padding-left: 15px !important;
            }
        }

        li {
            position: relative;
        }
        &.filelist {
            list-style-type: none;

            padding-left: 5px;
            li {
                a {
                    padding: 5px 0;
                    display: inline-block;
                }
                &:before {
                    content: none;
                }
            }
        }
        &.linklist {
            margin: 1.3em auto;
            .lh-border-radius(8px);
            padding: 0;
            width: 300px;
            max-width: 100%;
            li {
                background-color: #e7e9f0;
                a {
                    display: block;
                    padding: 10px;
                    text-align: center;
                    color: @grey;
                }
                &:hover {
                    background-color: fade(@color1, 50%);
                    a {
                        color: @white;
                        text-decoration: none;
                    }
                }
                &:before {
                    content: none;

                }
            }
        }
    }



    strong {
        font-weight: bold;
    }

    .intro {
        .font-size(18);
        line-height: 1.67;
        font-weight: normal;
    }

    .source {
        font-style: italic;
        font-size: 80%;
    }

    /*
    blockquote {
        display: block;
        text-align: center;
        background: @color1;
        color: @white;
        font-weight: 400;
        margin: 0;
        padding: 25px 25px;
        border: none;
        .font-size(28);
        .lh-border-radius(10);
        line-height: 1.25;

        @media @phone, @xsmall {
            .font-size(22);
        }

        &:before {
            content: '\201C';
        }
        &:after {
            content: '\201D';
        }
    }
    */

    a.knop,
    button.knop {
        display: inline-block;
        padding: 5px 35px;
        background: @color1;
        color: @white;
        text-decoration: none;
        font-weight: 700;
		text-transform: uppercase;
        .font-size(18);
        border: 1px solid @white;

        &:hover {
            background-color: @color2;
        }
    }

    .quote {
        border-top: 2px solid @color1;
        border-bottom: 2px solid @color1;
        padding: 10px 0;
        &.quote-big {
            .font-size(20);
        }
    }

  .text-big {
    .font-size(20);
  }

  strong, b {
    font-weight: 700;
  }

}
