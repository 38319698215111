section.content-24 {

    .col1 {
        .make-sm-column(3);
		@media @phone, @xsmall {
			margin-bottom: 30px;
		}

    }

    .col2 {
        .make-sm-column(9);
    }
}
