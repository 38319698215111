// external fonts
@import url(https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700);

@font-face {
  font-family: 'coconbold';
  src: url('../fonts/ufonts.com_cocon-bold-opentype-webfont.eot');
  src: url('../fonts/ufonts.com_cocon-bold-opentype-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ufonts.com_cocon-bold-opentype-webfont.woff2') format('woff2'),
  url('../fonts/ufonts.com_cocon-bold-opentype-webfont.woff') format('woff'),
  url('../fonts/ufonts.com_cocon-bold-opentype-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot');
    src: url('../fonts/icomoon.eot?#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.woff2') format('woff2'),
        url('../fonts/icomoon.woff') format('woff'),
        url('../fonts/icomoon.ttf') format('truetype'),
        url('../fonts/icomoon.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}
