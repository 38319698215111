.banner {
    display: block;
    text-align: left;
    //margin-bottom: 25px;
    background-color: fade(@color1, 30%);
    .clearfix;

    figure {
        position: relative;
        background-size: cover;
        background-position: center;
        text-align: center;
        //height: 110px;

        .make-sm-column(4);
        padding-left: 0;
        padding-right:0;

        @media @large {
            height: 170px;
        }
        @media @medium {
            height: 160px;
        }
        @media @small {
            height: 120px;
        }
        @media @phone, @xsmall {
            height: 150px;
        }

        /*&:after {
            display: none;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: fade(@color1, 50%);
            .icon;
            content: '\e901';
            color: @white;
            .font-size(40);
            text-align: center;

            @media @large {
                padding-top: 80px;
            }
            @media @medium {
                padding-top: 70px;
            }
            @media @small {
                padding-top: 80px;
            }
            @media @phone, @xsmall {
                padding-top: 60px;
            }
        }*/
    }
    span {
        display: block;
        .make-sm-column(8);
        padding-top: 15px;
        @media @small, @medium, @large {
            padding-left: 30px;
        }

        h3 {
            text-align: left;
            color: @color1 !important;
            .font-size(22) !important;
            font-weight: 400 !important;
            margin: 0 0 0.2em 0 !important;
        }

        p {
            text-align: left;
            .font-size(16) !important;
            line-height: 1.5 !important;
            color: @textcolor !important;
            font-weight: 300 !important;
            text-decoration: none !important;
            margin: 0 !important;
            @media @small {
                display: none;
            }
        }

        &:after {
            display: inline-block;
            float: right;
            content: 'lees meer';
            padding: 1px 12px;
            .lh-border-radius(4px);
            color: @color1;
            background: transparent;
            border: 1px solid @color1;
            .font-size(15) !important;
            font-weight: 400 !important;
            margin: 10px 0;
        }


    }


    &.active, &:hover {
        text-decoration: none !important;

        h3, p, span:after {
            text-decoration: none !important;
        }

        span:after {
            background: @color1;
            color: @white;
        }
    }

}

/*.banner-up,
.banner-down {
    display: block;
    border: 4px solid transparent;
    .lh-border-radius(9);
    height: 260px;

    figure {
        width: 90%;
        max-width: 170px;
        height: 170px;
        margin: 0 auto;
        background-repeat: no-repeat;
        background-size: contain;
        .lh-transition(transform 0.2s ease);
    }

    span {
        position: absolute;
        left: 50%;
        display: block;
        width: 160px;
        height: 69px;
        margin-left: -80px;


        h3 {
            color: @black;
            text-align: center;
            margin: 0;
            .font-size(20);
            font-weight: 600;
        }
    }

    &:hover {
        figure {
            .lh-transform(scale(1.15));
        }
    }

    &.active {
        text-decoration: none;
        border-color: @white;
        figure {
            .lh-transform(scale(1)) !important;
        }
    }
}

.banner-up {

    span {
        background: url('../images/balloon-down.png') no-repeat;
        padding: 35px 5px 0 5px;
        top: 145px;

    }
}

.banner-down {
    figure {
        margin-top: 70px;
    }

    span {
        background: url('../images/balloon-up.png') no-repeat;
        padding: 5px 5px 0 5px;
        top: 13px;
    }
}*/
