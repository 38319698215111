section.banners-128 {
    //margin-top: 30px;
    //margin-bottom: 30px;
    position: relative;
    .col1 {
        .make-sm-column(6);
        @medium @small, @medium, @large {
            width: 600px;
        }
        .col-centered;
    }
}

.banner-breed {
    .clearfix;
    position: relative;
    figure {
        float: right;
        width: 300px;
        height: 300px;
        @media @medium, @small {
            width: 200px;
            height: 200px;
        }
        @media @phone, @xsmall {
            width: 200px;
            height: 200px;
        }

        img {
            border-radius: 50%;
        }
    }
    span {
        display: inline-block;
        position: absolute;
        right: 280px;
        top: 10px;
        width: 240px;
        height: 240px;
        padding: 30px 40px;
        @media @phone, @xsmall {
            padding: 20px 30px;
            right: 60px;
            width: 200px;
            height: 200px;
            .font-size(17);
        }
        border-radius: 50%;
        background-color: fade(@color2, 90%);
        text-align: center;
        font-family: @titlefont;
        color: @white;
        .font-size(19);
        line-height: 1.3;
        letter-spacing: 0.5px;

        &:before {
            content: '';
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background-color: @color1;
            position: absolute;
            top: -10px;
            left: -20px;
            z-index: -1;
        }
    }
}
