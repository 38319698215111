// --------------------------------------------------
// Global settings
// --------------------------------------------------

// Colors
@black: #000;
@white: #FFF;

@orange: #f47b20;
@darkorange: #9f5015;
@lightorange: #f58836;

@green: #00968d;

@grey: #d6d2d1;
@mediumgrey: #4e5756;
@darkgrey: #222d2c;

@banner-text: @black;

@color1: @orange;
@color2: @green;
@contrast1: @mediumgrey;
@contrast2: @lightorange;
@textcolor: @darkgrey;
@titlecolor: @orange;

// mix with white: tint()
// mix with black: shade()


// Typography
@contentfont: 'PT Sans', Arial, Helvetica, sans-serif;
@titlefont: 'coconbold', @contentfont;
@subtitlefont: @titlefont;


// Templates
@leegheight: 100px; //400px;

// Breakpoints
/* Extra small vertical devices (phones, less than 480px) */
@phone:                     ~"only screen and (max-width: @{screen-xs-min})";

/* Extra small devices (horizontal phones, less than 768px) */
@xsmall:                    ~"only screen and (min-width: @{screen-xs-min}) and (max-width: @{screen-xs-max})";

/* Small devices (tablets, 768px and up) */
@small:                     ~"only screen and (min-width: @{screen-sm-min}) and (max-width: @{screen-sm-max})";

/* Medium devices (desktops, 992px and up) */
@medium:                    ~"only screen and (min-width: @{screen-md-min}) and (max-width: @{screen-md-max})";

/* Large devices (large desktops, 1200px and up) */
@large:                     ~"only screen and (min-width: @{screen-lg-min})";

/* Higher pixel density screens */
@retina:                    ~"only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi)";

@mobile: @phone, @xsmall;
@nonmobile: @small, @medium, @large;

