section.contact-132 {
    //padding-top: 0;
    //padding-bottom: 0;


    .col1 {
        .make-sm-column(12);
        .make-md-column(4);
    }

    .col2 {
        .make-sm-column(8);
        .make-md-column(6);
    }



}


section.contact-133 {
    //padding-top: 0;
    //padding-bottom: 0;


    .col1 {
        .make-sm-column(10);
        .make-md-column(8);
        .col-centered;
    }

    .col2 {
        .make-sm-column(10);
        .make-md-column(8);
        .col-centered;
    }

    label {
        text-align: left;
    }
    input[type="radio"], input[type="checkbox"] {
        margin-top: 9px;
    }

}
