section.referenties-201 {
    padding-bottom: 50px;

    .block {
        padding: 0 15px;

        .bx-wrapper,
        .bx-viewport {
            display: block;
            height: 100%;
            margin: 0;
            position: relative;
        }
        ul {
            .no-bullets;
            height: 100%;
            li {
                height: 100%;
                float: left;
                display: table;

                figure {
                    //background: lime;
                    height: 70px;
                    display: table-cell;
                    vertical-align: middle;
                    text-align: center;
                }
                img {
                    display: inline-block;
                    //margin: 0 auto;
                    max-height: 60px;
                    .lh-opacity(0.5);

                    filter: gray; /* IE6-9 */
                    -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
                }
                &:hover {
                    img {
                        .lh-opacity(1);
                        -webkit-filter: grayscale(0%);
                    }
                }

            }
        }
    }

}
