section.content-40 {

    .col1 {
        .make-sm-column(3);
        @media @phone, @xsmall {
            margin-bottom: 30px;
        }

    }
    .col2 {
        .make-sm-column(3);
        @media @phone, @xsmall {
            margin-bottom: 30px;
        }

    }

    .col3 {
        .make-sm-column(3);
        @media @phone, @xsmall {
            margin-bottom: 30px;
        }

    }
    .col4 {
        .make-sm-column(3);
        @media @phone, @xsmall {
            margin-bottom: 30px;
        }

    }
}
