section.banners-123 {

    .banner-container {
        //.make-row();
        //.make-sm-column(10);
        //.make-sm-column-offset(1);

        /*div {
            a {
                &:extend(.banner all);

                span {
                    text-align: left;
                    &:after {
                        .icon;
                        content: 'lees meer\0020\e900';
                        font-weight: normal;
                    }
                }
            }

            &:nth-child(odd){
                h3 {
                    color: @blue;
                }
                p, span:after {
                    color: @grey;
                }
            }

            &:nth-child(even){

                figure {
                    background-position: center bottom;
                }
                h3 {
                    color: @grey;
                }
                p, span:after {
                    color: @blue;
                }
            }
        }*/

    }
    .col1 {
        .make-sm-column(6);
        .make-md-column(3);
        @media @small {
            margin-bottom: @grid-gutter-width;
        }
    }
    .col2 {
        .make-sm-column(6);
        .make-md-column(3);
        @media @small {
            margin-bottom: @grid-gutter-width;
        }
    }

    .col3 {
        .make-sm-column(6);
        .make-md-column(3);
        @media @small {
            clear: both;
        }
    }
    .col4 {
        .make-sm-column(6);
        .make-md-column(3);
    }

    .banner {
        display: block;
        text-align: left;
        //margin-bottom: 25px;
        background-color: fade(@color1, 30%);
        .clearfix;

        figure {
            .make-sm-column(12);
            padding-left: 0;
            padding-right:0;

            @media @large {
                height: 170px;
            }
            @media @medium {
                height: 160px;
            }
            @media @small {
                height: 180px;
            }
            @media @phone, @xsmall {
                height: 150px;
            }
        }
        span {
            display: block;
            .make-sm-column(12);
            padding-top: 15px;
        }
    }

}
/*
.banners-123 + .banners-123 {
    @media @phone, @xsmall {
        margin-top: -50px;
    }
}*/
