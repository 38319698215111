* {
    .lh-box-sizing(border-box);
}

html {
    font-size: 62.5%;
}

html,
body {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    height: 100%;

}

body {
    font-family: @contentfont;
    color: @textcolor;
    .font-size(18);
    line-height: 1.8;

    @media @phone, @xsmall {
        //.font-size(18);
    }
}

a {
    color: @color1;
    text-decoration: none;

    &:link {
        text-decoration: none;
        outline: 0;
    }

    &:visited {
        text-decoration: none;
        outline: 0;
    }

    &:active {
        text-decoration: none;
        outline: 0;
    }

    &:hover {
        text-decoration: underline;
        outline: 0;
    }
    .filelist & {

        &[href $='.pdf'],
        &[href $='.xls'],
        &[href $='.xlsx'],
        &[href $='.doc'],
        &[href $='.docx'],
        &[href $='.zip'],
        &[href $='.ppt'],
        &[href $='.pptx'] {
            &:not(.no-icon){
                &:before {
                    .icon;
                    display: inline-block;
                    margin-right: 5px;
                    font-size: 120%;
                    vertical-align: text-bottom;
                    content: "\e907";
                }
            }
        }
        &[href $='.pdf']:before { content: '\f1c1' !important;}
        &[href $='.xls']:before,
        &[href $='.xlsx']:before { content: '\f1c3' !important;}
        &[href $='.doc']:before,
        &[href $='.docx']:before { content: '\f1c2' !important;}
        &[href $='.ppt']:before,
        &[href $='.pptx']:before { content: '\f1c4' !important;}
        &[href $='.jpg']:before,
        &[href $='.png']:before { content: '\f1c5' !important;}
        &[href $='.zip']:before { content: '\f1c6' !important;}
    }
}

.header {
    background: @white;
    position: relative;
    z-index: 25;
    .clearfix();
    .container {
        padding: 0;
    }

    @media @phone, @xsmall {
        .container {
            padding: 0 15px;
        }
    }

    .logo {
        position: relative;
        z-index: 20;
        .make-xs-column(8);
        .make-sm-column(5);
        .make-md-column(4);
        padding: 0;
		margin: 15px 0;
        a {
            display: block;
            height: 100%;
        }
        img {
            max-width: 100%;
        }

        @media @small {
            //margin-top: 25px;
        }

        @media @phone, @xsmall {
        }
        .clearfix;
    }

  .navbar-header {
    .make-xs-column(4);
    margin: 10px 0;
    padding: 0;
    .navbar-toggle {
      margin-right: 0;
      border-radius: 0;
      border-color: @color1;
      .icon-bar {
        background-color: @color1;
      }
    }

  }

}


.header-options {
    .make-sm-column(7);
    .make-md-column(8);


	
    text-align: right;
  @media @phone, @xsmall {
    display: none;
  }
  .contact {
      display: inline-block;
      a {
            display: block;
            color: @white;
            text-align: center;
        }
        position: relative;
        width: 200px;
        height: 95px;
        &:before {
            position: absolute;
            left: 0;
            right: 0;

            height: 100%;
            top: 0;
            background-color: @color2;
            border-bottom-left-radius: 200px;
            border-bottom-right-radius: 200px;
            content: '';
            z-index: -1;
            padding-top: 5px;
            margin-top: -5px;
        }
    }
    .lang-select {
        display: inline-block;
        vertical-align: top;
        margin-top: 10px;
        margin-bottom: 15px;

        @media @phone, @xsmall {
            margin-top: 10px;
        }
        @media @small {
            margin-bottom: 10px;
        }

        ul {
            .no-bullets;
            height: 20px;

            li {
                display: inline-block;
                height: 20px;
            }
        }

        a {
            display: inline-block;
            width: 30px;
            height: 20px;
            margin: 0 5px;
            background-repeat: no-repeat;
            vertical-align: top;


            &.flag_nl {
                background-image: url('../images/flag_nl.png');
            }
            &.flag_en {
                background-image: url('../images/flag_uk.png');
            }
            &:last-child {
                margin-right: 0;
            }

            .lh-opacity(0.5);

            //filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
            filter: gray; /* IE6-9 */
            -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */

            &.active, &:hover {
                .lh-opacity(1);
                //filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
                -webkit-filter: grayscale(0%);
            }
        }
    }
}



/*
.searchform {

    input {
        float: left;
        width: 200px;
        padding: 0 10px;
        border: 1px solid #b5baba;
        border-right: none;
        background: @white;
        height: 35px;
        vertical-align: middle;
        -webkit-appearance: none;
        .lh-border-radius(0);
    }
    button {
        float: left;
        width: 30px;
        text-align: center !important;
        border: 1px solid #b5baba;
        border-left: none;
        background: @white;
        height: 35px;
        vertical-align: middle;
        line-height: 1;
        -webkit-appearance: none;
        padding-top: 5px;

        &:after {
            .icon;
            color: fade(@grey, 40%);
            content: '\e900';
            .font-size(20);
        }
    }
}*/

/*

.searchresult {
    .make-md-column(10);
    padding: 0;
    margin: 0;

    .font-size(18) !important;
    font-weight: normal !important;

    h3 {
        .font-size(20) !important;
        margin: 0 !important;
    }
    p {

    }

    &:hover {
        color: @color1 !important;
        text-decoration: none !important;

        h3 {
            color: @color1 !important;
        }
    }
}
*/

.mainmenu {

    position: relative;
    z-index: 10;
    background-color: fade(@white, 80%);
    .container {
        padding: 0;
    }
    @media @large {
        height: 50px;
        margin-bottom: -50px;
    }
    @media @medium {
        height: 50px;
        margin-bottom: -50px;
    }
    @media @small {
        height: 50px;
        margin-bottom: -50px;
    }

    @media @phone, @xsmall {
        .container {
            padding: 0;
        }
      position: fixed;
      width: 100%;
    }

    .menu {
        position: relative;
        z-index: 2;
        padding: 0;
        .lh-box-shadow(none);

        @media @small, @medium, @large {

        }

        @media @phone, @xsmall {
            padding: 5px 15px;
        }
        > ul {
            width: 100%;
            .no-bullets;
            .clearfix;
            text-align: left;
            @media @phone, @xsmall {
                padding-bottom: 15px;
            }

            > li {
                position: relative;
                display: inline-block;
              &.mobiel {
                display: none;
              }
              @media @phone, @xsmall {
                padding: 0;
                display: block;
                width: 100%;
                &.mobiel {
                  display: block;
                  padding: 6px 15px;
                  a {
                    &.flag_nl {
                      width: 30px;
                      height: 20px;
                      margin: 0 5px;
                      background-repeat: no-repeat;
                      background-image: url('../images/flag_nl.png');
                      .lh-opacity(0.5);

                      //filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
                      filter: gray; /* IE6-9 */
                      -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
                    }
                    &.flag_en {
                      width: 30px;
                      height: 20px;
                      margin: 0 5px;
                      background-repeat: no-repeat;
                      background-image: url('../images/flag_uk.png');
                      .lh-opacity(0.5);

                      //filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
                      filter: gray; /* IE6-9 */
                      -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
                    }

                    &.active, &:hover {
                      .lh-opacity(1);
                      //filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
                      -webkit-filter: grayscale(0%);
                    }

                    vertical-align: middle;
                    padding: 0;
                    display: inline-block;
                    &:after {
                      .icon;
                      display: inline-block;
                      width: 32px;
                      height: 32px;
                      text-align: center;
                      line-height: 32px;
                      .font-size(24);
                    }
                    &.email:after {
                      content: '\f0e0';
                    }
                    &.phone:after {
                      content: '\f095';
                    }
                    &.marker:after {
                      content: '\f041';
                    }
                    &.facebook:after {
                      content: '\e904';
                      color: #1877f2;
                    }
                    &.youtube:after {
                      content: '\e903';
                      color: #f00;
                    }
                  }

                }
                }

                a {
                    display: block;
                    color: @textcolor;
                    .font-size(18);
                    font-weight: 400;


                    //.lh-transition(background 0.15s ease);

                    @media @phone, @xsmall {
                        padding: 5px 15px;
                        height: auto;
                        text-align: left;
                        .font-size(15);
                    }

                    @media @small, @medium, @large {
                        line-height: 1;
                        text-transform: uppercase;
                        text-align: center;
                    }

                    @media @small {
                        .font-size(16);
                        padding: 16px 8px 16px;
                        max-height: 57px;
                    }

                    @media @medium {
                        padding: 16px 12px ;
                        max-height: 67px;
                    }

                    @media @large {
                        max-height: 50px;
                        padding: 20px 15px;
                    }

                }

                &:first-child {

                }

                &:hover {
                    > a {
                        text-decoration: none;
                        color: @color1;
                    }

                    ul {
                        display: block;
                    }
                }



                @media @small, @medium, @large {
                    display: inline-block;

                    > a {
                        //letter-spacing: 0.25px;
                    }

                    &.active {
                        > a {
							color: @color2;
                        }
                    }

                }

                ul {
                    .no-bullets;
                    //.lh-box-shadow(2px 2px 4px 0 rgba(0,0,0,0.2));
                    text-align: left;
                    //background-color: fade(@color1, 80%);

                    @media @small, @medium, @large {
                        display: none;

                        position: absolute;
                        left: 0;
                        top: 100%;
                        z-index: 15;
                        //background: @white;
                        min-width: 300px;
                        //border: 1px solid #bbb;
                        border-top: none;
                        //padding: 10px 0 0 0;

                        li {

                            a {
                                display: block;
                                text-align: left;
                                color: @white;
                                background: fade(@color2, 80%);
                                //background: fade(@white, 90%);
                                .lh-transition(background 0.2s ease);
                                padding-top: 8px;
                                padding-bottom: 8px;
                                text-transform: none;

                                &:hover {
                                    color: @white;
                                    background-color: @color1;
                                    text-decoration: none;
                                }
                            }
                        }
                    }

                    @media @phone, @xsmall {
                        margin: 0 0 15px 0;

                        li {
                            a {
                                display: block;
                                .font-size(14);
                                line-height: 1.3;
                                text-transform: none;
                                padding: 5px 15px 5px 30px;
                                font-weight: 300 !important;
                            }
                        }
                    }

                }

                &:last-child {
                    ul {
                        left: auto;
                        right: 0;
                    }
                }

            }
        }
    }
}


.top-carrousel {
    position: relative;
    width: 100%;
    z-index: 0;

    @media @large {
		height: 680px;
        margin-bottom: 130px;
    }
    @media @medium {
		height: 480px;
        margin-bottom: 100px;
    }
    @media @small {
      height: 480px;
      margin-bottom: 100px;
    }
    @media @phone, @xsmall {
      height: 320px;
    }

    .bx-wrapper,
    .bx-viewport {
        display: block;
        height: 100%;
        overflow: visible !important;
    }

    ul {
        .no-bullets;
        height: 100%;
        li {

            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            .container {
                position: relative;
                height: 100%;
                z-index: 2;
                padding: 0;
            }

            a {
                display: block;
                height: 100%;
                span:after {
                    display: inline-block;
                    content: 'lees meer';
                    .font-size(16);
                    color: @color1;
                    border: 2px solid @white;
                    padding: 6px 15px;
                    background-color: fade(@white, 80%);
                    .lh-border-radius(4px);
                    float: right;
                    margin-bottom: 10px;

                    @media @phone, @xsmall {
                        .font-size(16);
                        padding: 7px 30px;


                    }
                }

                &:hover {
                    span {
                        background: @color1;
                        &:after {
                            background-color: @color1;
                            color: @white;
                        }
                    }
                }
            }


            span {
                &:before {
                    position: absolute;
                    bottom: -10px;
                    width: 200px;
                    height: 200px;
                    right: -10px;
                    border-radius: 50%;
                    background-color: fade(@color1, 90%);
                    z-index: -1;
                    content: '';
                  @media @medium, @small {
                    width: 100px;
                    height: 100px;
                  }
                  @media @phone, @xsmall {
                    content: none;
                  }
                }
                padding: 70px 60px;
                display: block;
                width: 410px;
                height: 410px;
                border-radius: 50%;
                position: absolute;
                right: 10px;
                bottom: -100px;
                background-color: fade(@color2, 90%);
              @media @medium, @small {
                bottom: -70px;
                width: 300px;
                height: 300px;
                padding: 50px 40px;
              }
                @media @phone, @xsmall {
                    right: 0;
                    width: 100%;
                  height: auto;
                  bottom: 0;
                  padding: 10px 20px;
                  -webkit-border-radius: 0;
                  -moz-border-radius: 0;
                  border-radius: 0;
                }
                h4 {
                    font-weight: normal;
                    color: @white;
                    font-family: @titlefont;
                    line-height: 1.3;
                    text-align: center;
                    word-wrap: break-word;
                    .font-size(35);
                    @media @medium, @small {
                        .font-size(26);
                    }

                    @media @phone, @xsmall {
                        .font-size(20);
                    }
                }

                h6 {
                    display: none;
                }

            }
        }
    }
    .bx-controls {
        //position: relative;
        z-index: 20;

        @media @phone, @xsmall, @small {
            display: none;
        }

        .bx-controls-direction {
            a {
                background: fade(@white, 40%);
                text-indent: 0;
                width: 60px;
                height: 60px;
                margin-top: -25px;
                padding-top: 15px;
                text-align: center;
                text-decoration: none;
                &:before {
                    .icon;
                    .font-size(30);
                    color: @grey;

                }

                &.bx-prev {
                    left: 40px;

                    &:before {
                        content: '\e901';
                    }
                }
                &.bx-next {
                    right: 40px;

                    &:before {
                        content: '\e900';
                    }
                }

                &:hover {
                    background: fade(@color1, 70%);
                    &:before {
                        color: @white;
                    }

                }
                @media @small, @phone, @xsmall {
                    background: fade(@black, 30%);
                    &.bx-prev {
                        left: 0;
                    }
                    &.bx-next {
                        right: 0;
                    }

                }
            }

        }
    }
}
.header-image {

    //.container;
    background-position: center;
    background-size: cover;

    position: relative;
    width: 100%;
    z-index: 0;


  @media @large {
    height: 680px;
  }
  @media @medium {
    height: 480px;
  }
  @media @small {
    height: 480px;
  }
  @media @phone, @xsmall {
    height: 320px;
  }
}

.bg-image {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 65%;
    height: 100%;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;

}

.pageheader {
    position: relative;
    z-index: 9;

   hgroup {
       position: relative;
       z-index: 2;

       h1 {
           &:extend(.zerniq_wysiwyg h1 all);
       }
       h4 {
           &:extend(.zerniq_wysiwyg h3 all);
       }



    }
}


.breadcrumbs {
    clear: both;
    position: relative;
    z-index: 3;
    background: @white;
    .font-size(16);
    color: #ababab;
    font-family: @contentfont;
    text-transform: lowercase;

    @media @small, @medium, @large {
        margin-top: 60px;
      border-top: dotted 5px @color2;

        .header-image + &,
        .top-carrousel + & {
          border-top: none;
            margin-top: 0;
        }
    }

    @media @phone, @xsmall {
        .font-size(14);
    }


    .container {
        padding-top: 7px;
        padding-bottom: 7px;
        //padding-left: 0;
        //padding-right: 0;
        background: transparent;
        margin-bottom: 15px;
    }
    ul, ol {
        .no-bullets;

        li {
            display: inline-block;

            a {
                color: #ababab;
                &:hover {
                    color: @color1;
                    text-decoration: none;
                }
            }

            &:after {
                content: '|';
                display: inline-block;
                padding: 0 2px 0 5px;
            }

            &:last-child {
                color: #ababab;
                a {
                    color: #ababab;
                }
                &:after {
                    content: '';
                }
            }
        }
    }

    .sticky-wrapper + & {
        padding-top: 15px;
    }
}

.sidemenu {
    @media @phone, @xsmall {
        display: none;
    }

    ul {
        .no-bullets;
        li {
            border-bottom: 1px solid fade(@color1, 60%);
            a {
                position: relative;
                display: block;
                .font-size(18);
                font-weight: 300 !important;
                line-height: 1.1;
                color: @black !important;
                padding: 15px 8px 8px 8px !important;

                &:hover {
                    text-decoration: none !important;
                    background-color: fade(@color1, 20%);
                }
            }

            &.active {
                border-bottom: 0;
                > a {
                    background-color: fade(@color1, 83%);
                    color: @white !important;
                }
            }

            ul {
                border-top: 1px solid fade(@grey, 50%);
                li {
                    padding-left: 20px;
                    border-bottom: 1px solid fade(@grey, 50%);

                    &:last-child {
                        border: none;
                    }
                }
            }
        }
    }

    .page-home & {
        ul {
            li {
                a {

                }
            }
        }
    }
}


.content {
    clear: both;
    position: relative;
    z-index: 2;
    min-height: 200px;


    > section {
        padding-top: 25px;
        padding-bottom: 25px;

        p:last-child {
            margin-bottom: 0;
        }

        /*&:first-child {
            @media @small, @medium, @large {
                padding-top: 50px;
            }

            @media @phone, @xsmall {
                padding-top: 30px;
            }
        }*/

        @media @phone, @xsmall {
            //margin: 0 15px;
        }

        .block {
            .clearfix;
            //min-height: 50px;
            &:extend(.zerniq_wysiwyg all);

        }
        &:not(.wide){
            > .block {
                .container;
                padding: 0;
            }
        }
        &.wide {
            > .block {
                .container-fluid;
                padding: 0;
            }
        }


        &.first-block {
            .col-left {
                .make-sm-column(4);
                .make-md-column(3);
            }
            .col-right {
                .make-sm-column(8);
                .make-md-column(9);
            }
        }

        &.bgcolor-1 {
            background: @color1;
            *, a, .block-title {
                color: @white;
            }
            h4 {
                color: @white;
            }
            ul {
                li {
                    &:before {
                        color: @white;
                    }
                }
            }

        }

        &.bgcolor-2 {
            background: @grey;
        }

        &.bgcolor-3 {
            .block:last-child {
                border-bottom: dotted 5px @grey;
              padding-bottom: 25px;
            }
          padding-bottom: 0;
        }



        // Homepage exceptions

        /*&:first-child {
            .page-home & {
                padding-top: 0;
                background: @grey;
                .block * {
                    color: @white !important;
                }
            }
        }*/

        &:not([class^='content-1']) .block-title {
            .make-sm-column(12);
        }
    }


    //.banners-120, .banners-121, .banners-122, .banners-123 {

    //}


    .image-block {
        min-height: 100px !important;
        background-position: center;
        background-size: cover;
    }
}

.addthis {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    text-align: center;

}

.fit-vids-style {
    max-height: 0;
}

.newslink {

   /* figure {
        .make-sm-column(4);
        height: 170px;
        background-position: center;
        background-size: cover;
    }*/
    padding-bottom: 40px;
    display: block;
    span {
        //.make-sm-column(8);
        position: relative;
        display: block;
        h3 {
            height: 26px;
            color: @color1 !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            .font-size(24) !important;
            margin: 0 0 4px 0 !important;
            text-transform: none !important;
        }
        time {
            .font-size(13) !important;
            color: #999999;
            margin-bottom: 3px !important;
        }
        p {
            .font-size(15) !important;
            font-weight: normal !important;
            margin: 0 !important;
            max-height: 81px;
            overflow: hidden;
            color: @textcolor;
        }

        &:after {
            display: inline-block;
            text-align: center;
            position: absolute;
            left: 50%;
            width: 32px;
            height: 32px;
            margin-left: -16px;
            margin-top: 10px;
            .icon;
            line-height: 32px;
            content: '\e901';
            color: @white;
            background-color: @color1;
            .lh-border-radius(20);
            .font-size(20) !important;
            font-weight: normal !important;
        }
    }

    &:hover {
        text-decoration: none !important;
        h3 {
            color: @color1 !important;
        }
        span:after {
            background: @color1;
        }
    }
}
/*.banner,
.newslink {
    @media @large {
        height: 520px;
    }
    @media @medium {
        height: 200px;

    }

    @media @small {
        height: 260px;
    }

    @media @phone, @xsmall {
        height: 220px;
        margin: 0 0 @grid-gutter-width 0;
    }
}*/
.news-single-item {
    &:extend(.content-10 all);
    .block {
        padding: 0 !important;
    }
    .fluid-width-video-wrapper {
        margin-bottom: 10px;
    }

    nav {
        .make-sm-column(3);

        @media @phone, @xsmall {
            display: none;
        }

        ul {
            .no-bullets;
            margin: 0 !important;
            li {
                //margin-bottom: 10px;
                a {
                    .font-size(16);
                    text-align: left !important;
                    line-height: 1.1 !important;
                    color: @grey !important;
                    &:hover {
                        color: @color1 !important;
                        text-decoration: none !important;
                    }
                    small {
                        .font-size(12);
                    }
                }
                &:before {

                }
            }
        }
    }
    article {
        .make-sm-column(9);
        @media @phone, @xsmall {
            padding: 0 15px;
        }
    }
}
.deurmat {
    position: relative;
    z-index: 2;
    background: @lightorange;
    color: @white;

    .container {
        padding-top: 30px;
        padding-bottom: 15px;
    }

    a {
        color: @white;
        &:hover {
            text-decoration: none;
            color: @darkgrey;
        }
    }

    .column {
        .make-sm-column(3);
        &.has-iframe {
          padding: 0;
        }
        iframe {
          background-color: transparent;
          border: 0px none transparent;
          padding: 0px;
          overflow: hidden;
        }
       /* @media @small {
            &:nth-child(3){
                clear: both;
            }
        }*/

        @media @phone, @xsmall {
            margin-bottom: 15px;
        }
    }

    p {
        margin: 0 0 20px 0;
    }

    p + ul {
        margin-top: -20px;
    }

    ul {
        .no-bullets;
        //margin: 0 0 20px 0;
        list-style-type: none;

        li {
            position: relative;
            padding-left: 8px;
            /*&:before {
                position: absolute;
                left: 0;
                top: 0;
                content: "-\0020";
            }*/
        }
    }

}
.footer {
    position: relative;
    z-index: 2;
    padding: 8px 0;
    background: @color2;

    .block {
       .clearfix;
        &:extend(.zerniq_wysiwyg all);
       .container;
        padding: 0;
    }

    .font-size(14);

    .copyright {
        .make-sm-column(6);
        color: @white;
        text-align: right;
    }
    .links {
        .make-sm-column(6);
        color: @white;


        a + a {
            margin-left: 20px;
        }
        ul {
            .no-bullets;
            li {
                display: inline-block;
                margin-left: 12px;
            }
        }
    }

}


.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  border-top: none;
}

.block-8 {
  tr > td {
    padding-left: 0;
    padding-right: 0;
    &:last-child {
      white-space: nowrap;
    }
  }
}

iframe[seamless]{
  background-color: transparent;
  border: 0px none transparent;
  padding: 0px;
  overflow: hidden;
}

.socials {
  position: fixed;
  top: 30%;
  right: 0;

  z-index: 5;
  display: none;
  @media @nonmobile {
    display: block;
  }
  &:before {
    position: absolute;
    right: 0;
    height: 100%;
    width: 100%;
    top: 0;
    background-color: #00968d;
    border-top-left-radius: 200px;
    border-bottom-left-radius: 200px;
    content: '';
    z-index: -1;
  }
    a {
      vertical-align: middle;
      padding: 10px;
      margin: 30px 10px 30px 30px;
      display: block;
      border-radius: 50%;
      background-color: @white;
      &:after {
        .icon;
        display: inline-block;
        width: 48px;
        height: 48px;
        text-align: center;
        line-height: 48px;
        .font-size(42);

      }
      &.facebook:after {
        content: '\e904';
        color: #1877f2;
      }
      &.youtube:after {
        content: '\e903';
        color: #f00;
      }
    }
}